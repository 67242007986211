<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    :persistent="status.adding"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <h5 class="text-h5 font-weight-bold">Create New Form</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Form name"
            outlined
          ></v-text-field>

          <div class="d-flex gap-5">
            <v-btn  
              @click="validateForm()"
              :loading="status.adding"
              color="primary"
              small
            >Create</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.orderform.data,
      status: state => state.orderform.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'createForm'
    ]),

    showDialog() {
      this.dialog = true
    },
    
    closeDialog() {
      this.dialog = false
      this.$store.commit('orderform/resetData')
      this.$refs.form.resetValidation()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.createForm())
        .then((id) => {
          this.closeDialog()
          if (id) this.$router.push({ name: 'CreteOrderForm', params: { id }, query: { view: 'editor' } })
        })
      }
    }
  }
}
</script>