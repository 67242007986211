<template>
  <v-sheet 
    :max-width="small ? '100%' : '295'" 
    :class="{ 'isGrey' : grey }"
    width="100%" 
    v-if="form"
  >
    <v-hover v-if="noneRoute" v-slot="{ hover }">
      <FormImage :form="form" :hover="hover" />
      <v-card
        @click="$emit('selected')"
        :style="{ transition: 'all 0.3s' }"
        :class="{ 'box-shadow': hover }"
        class="rounded-lg"
        outlined
      >
        <v-card-text class="pa-5 pt-0 text-center">
          <h4 class="mb-0 subtitle-1" :style="{ lineHeight: '1.1' }">{{ form.name }}</h4>
          <!-- <p style="color: rgba(19,45,61,0.5)" class="mb-0 caption">{{ type.description }}</p> -->
        </v-card-text>
      </v-card>
    </v-hover>
    <v-hover v-else v-slot="{ hover }">
      <v-card
        :to="route"
        :style="{ transition: 'all 0.3s' }"
        :class="{ 'box-shadow': hover }"
        outlined
      >
        <FormImage :form="form" :hover="hover" />
        <v-card-text class="pa-5 pt-0">

          <h4 class="mb-3 subtitle-1 text-center" :style="{ lineHeight: '1.1' }">{{ form.name }}</h4>
          <p style="color: rgba(19,45,61,0.8)" class="caption">{{ form.description }}</p>

          <v-btn color="accent gradient" block>Order Now</v-btn>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-sheet>
</template>

<script>
import FormImage from './FormImage'

export default {
  props: {
    form: Object,
    grey: Boolean,
    route: Object,
    small: Boolean,
    action: Boolean,
    product: Boolean,
    noneRoute: Boolean,
  },

  components: {
    FormImage,
  }
}
</script>

<style lang="scss">

.isGrey {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.5;
}

</style>