<template>
  <div class="mt-5">
    <div class="d-flex align-center py-3 mb-5">
      <v-sheet width="100%" max-width="450">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="custom-shadow"
          placeholder="Search"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-sheet>

      <v-spacer></v-spacer>

      <v-btn 
        @click="$refs.dialog.showDialog()" 
        class="input-shadow"
        color="primary"
        small
      >Create New Form</v-btn>
    </div>

    <v-row>
      <!-- LIST -->
      <v-col>
        <preloader v-if="status.getting" />

        <v-alert :value="!status.getting && !forms.length" type="info" border="left" text>
          There are no order forms yet.
        </v-alert>

        <div v-if="!status.getting && forms.length" class="d-flex gap-20">
          <div
            v-for="form in confForms" 
            :key="form.id"
            :style="{ minWidth: '250px' }"
          >
            <FormCard :form="form" :route="{ name: 'CreteOrderForm', params: { id: form.id }, query: { view: 'editor' } }"/>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- CREATE FORM DIALOG -->
    <create-form-dialog ref="dialog" />

    <!-- CONFIRM DELETE DIALOG -->
    <confirm-delete
      @confirmed="deleteConfirmed()"
      :deleting="status.deleting"
      :message="deleteMessage"
      @cancel="closeDialog()"
      textConfirmation="Form"
      :show="showDialog"
      captcha
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormCard from './components/FormCard.vue'
import CreateFormDialog from './components/CreateFormDialog.vue'

export default {
  metaInfo: {
    title: 'Order Forms'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      toDelete: null,
      showDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      forms: state => state.orderform.forms,
      status: state => state.orderform.status,
    }),

    confForms: function () {
      return this.forms.filter(form => {
        if (this.search) {
          return form.name.toLowerCase().includes(this.search.toLowerCase())
        }
        else return true
      })
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: { 
    FormCard,
    CreateFormDialog,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'unpublishForm',
      'publishForm',
      'deleteForm',
      'getForms',
    ]),

    confirmDelete(form) {
      this.toDelete = form
      this.deleteMessage = `Delete form <strong>${this.toDelete.name}</strong>?`
      this.showDialog = true
    },

    closeDialog() {
      this.toDelete = null
      this.showDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteForm(this.toDelete))
      .then(() => {
        this.closeDialog()
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getForms()
  }
}
</script>